'use client';
import Image from 'next/image';

import hubspotLogo from '../public/hubspot.svg';
import outreachLogo from '../public/outreach.svg';
import salesforceLogo from '../public/salesforce.svg';
import salesforceSandboxLogo from '../public/salesforcesandbox.svg';

const pipedriveUrl = `https://oauth.pipedrive.com/oauth/authorize?client_id=490a93528d5c4bd6&redirect_uri=https://portal.generatemomentum.ai/api/pipedrive/oauth-callback`;

const outreachScopes = [
  'accounts.all',
  'prospects.all',
  'sequences.all',
  'emailAddresses.all',
  'sequenceTemplates.all',
  'sequenceSteps.all',
  'sequenceStates.all',
  'snippets.all',
  'templates.all',
  'users.all',
  'mailings.all',
];

const redirectUrlOutreach = encodeURI('https://portal.generatemomentum.ai/api/outreach/oauth-callback');

const scopesText = encodeURI(outreachScopes.join('+'));
const urlOutreach = `https://api.outreach.io/oauth/authorize?client_id=z90TjDGxoCMOP4SO~XWYStWt8-vlSl21Ne3P89M8-ZBq&redirect_uri=${redirectUrlOutreach}&response_type=code&scope=${scopesText}`;

const handleOutreachClick = () => {
  window.location.href = urlOutreach;
};

const getSalesforceLoginUrl = (isSandbox: boolean = false) => {
  const url = isSandbox ? 'https://test.salesforce.com' : 'https://login.salesforce.com';
  return url;
};

const scopes = ['api', 'refresh_token', 'offline_access'];

const salesloftUrl = `https://accounts.salesloft.com/oauth/authorize?client_id=110445_5b249e17d3768d04a29cbea67cc5e03184721181a4e1ea40ce1dea1c8693f89e&redirect_uri=https://portal.generatemomentum.ai/api/salesloft/oauth-callback&response_type=code`;

const handleSalesforceClick = (isSandbox: boolean = false) => {
  const salesforceUrl = new URL(`${getSalesforceLoginUrl(isSandbox)}/services/oauth2/authorize`);
  salesforceUrl.searchParams.append('response_type', 'code');
  salesforceUrl.searchParams.append('state', isSandbox ? 'sandbox' : '');
  salesforceUrl.searchParams.append('client_id', '3MVG9IXUyidRC0l3KRodx3Bk9tMXQ.R7z3OgDBvwHdzEETonvy5V6V9S9hck9QhN0QRQFtwpx.lUYWtlOWf2X');
  salesforceUrl.searchParams.append('redirect_uri', 'https://portal.generatemomentum.ai/api/salesforce/oauth-callback');
  salesforceUrl.searchParams.append('scope', scopes.join(' '));
  window.location.href = salesforceUrl.toString();
};

const handleHubspotClick = () => {
  const urlHubspot = new URL(
    'https://app-eu1.hubspot.com/oauth/authorize?client_id=75c3b7e7-078b-4dde-93b1-40d76ad1139c&redirect_uri=https://portal.generatemomentum.ai/api/hubspot/oauth-callback&scope=crm.objects.contacts.read%20crm.objects.custom.read%20crm.objects.companies.read',
  );
  window.location.href = urlHubspot.toString();
};

export default function Home() {
  function handleSalesloft() {
    window.location.href = salesloftUrl;
  }

  function handlePipedrive() {
    window.location.href = pipedriveUrl;
  }

  return (
    <main className="flex flex-col items-center justify-start p-24 min-h-screen">
      <h1 className="text-center text-purple-600 text-5xl mb-6">Momentum</h1>
      <p className="text-center text-gray-700 mb-6">
        You need to sign up with an account and authenticate your Salesforce and Outreach accounts so we can integrate with them.
      </p>

      <div className="flex space-x-4 flex-wrap">
        <button
          className="bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-6 m-2 rounded-3xl flex items-center justify-center"
          onClick={handleOutreachClick}
        >
          <Image src={outreachLogo} className="p-4" alt="Outreach Logo" width={300} height={300} />
        </button>

        <button
          className="bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-6 m-2 rounded-3xl flex items-center justify-center"
          onClick={() => handleSalesforceClick(false)}
        >
          <Image src={salesforceLogo} className="p-12" alt="Salesforce Logo" width={300} height={300} />
        </button>

        <button
          className="bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-6 m-2 rounded-3xl flex items-center justify-center"
          onClick={() => handleSalesforceClick(true)}
        >
          <Image src={salesforceSandboxLogo} className="p-12" alt="Salesforce Sandbox Logo" width={300} height={300} />
        </button>

        <button
          className="bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-6 m-2 rounded-3xl flex items-center justify-center"
          onClick={handleHubspotClick}
        >
          <Image src={hubspotLogo} className="p-12" alt="Hubspot Logo" width={300} height={300} />
        </button>
        <button
          className="bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-6 m-2 rounded-3xl flex items-center justify-center"
          onClick={handleSalesloft}
        >
          <Image src="/salesloft.png" className="p-12" alt="Hubspot Logo" width={300} height={300} />
        </button>
        <button
          className="bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-6 m-2 rounded-3xl flex items-center justify-center"
          onClick={handlePipedrive}
        >
          <Image src="/pipedrive.png" className="p-12" alt="Hubspot Logo" width={300} height={300} />
        </button>
      </div>
    </main>
  );
}
